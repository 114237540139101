import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal
      title="Happy 40th birthday, Claudia!"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <p className="text-sm text-black">
        Guess the word in 6 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="C"
          status="correct"
        />
        <Cell value="H" />
        <Cell value="L" />
        <Cell value="O" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-black">
        The letter C is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="D" />
        <Cell value="A" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="N"
          status="present"
        />
        <Cell value="C" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-black">
        The letter N is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="L" />
        <Cell value="E" />
        <Cell value="G" />
        <Cell isRevealing={true} isCompleted={true} value="A" status="absent" />
        <Cell value="L" />
      </div>
      <p className="text-sm text-black">
        The letter A is not in the word in any spot.
      </p>

      <p className="mt-6 italic text-sm text-black">
        By the way, if you get the answer right in 6 tries or less, you get a
        prize. There are 40 prizes to be won.
      </p>
    </BaseModal>
  )
}
