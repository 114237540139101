export const WORDS = [
  'smith',
  'board',
  'dance',
  'fruit',
  'hotel',
  'japan',
  'plant',
  'river',
  'dress',
  'boobs',
  'cross',
  'chloe',
  'sugar',
  'novel',
  'snack',
  'bread',
  'touch',
  'pasta',
  'beach',
  'forty',
  'hello',
  'kitty',
  'sleep',
  'cafes',
  'steak',
  'spicy',
  'onsen',
  'books',
  'chair',
  'paper',
  'watch',
  'class',
  'pearl',
  'stars',
  'mixer',
  'photo',
  'benji',
  'sushi',
  'drink',
  'meows',
]
