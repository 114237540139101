import { useEffect, useState } from 'react'
// import Countdown from 'react-countdown'
import { StatBar } from '../stats/StatBar'
import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { PRIZES } from '../../constants/prizes'
// import { shareStatus } from '../../lib/share'
import { solution } from '../../lib/words'
import { BaseModal } from './BaseModal'
import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  // NEW_WORD_TEXT,
  // SHARE_TEXT,
} from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
}

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  numberOfGuessesMade,
}: Props) => {
  const [prize, setPrize] = useState<string | undefined>()

  useEffect(() => {
    for (const x of PRIZES) {
      if (x.answer.toUpperCase() === solution) {
        const prize = x.prize
        setPrize(prize)
      }
    }
  }, [])

  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal
        title={STATISTICS_TITLE}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <StatBar gameStats={gameStats} />
      </BaseModal>
    )
  }

  return (
    <BaseModal
      title={STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium text-black">
        {GUESS_DISTRIBUTION_TEXT}
      </h4>
      <Histogram
        gameStats={gameStats}
        numberOfGuessesMade={numberOfGuessesMade}
      />
      {isGameWon && (
        <p className="text-sm leading-6 font-medium text-black">
          {' '}
          Congratulations! You have unlocked {prize}! Please show this screen to
          Pat.
        </p>
      )}
      <button
        className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-brandgreen text-brandpink-100 dark:text-brandgreen dark:bg-brandpink-200 font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
        type="button"
        onClick={() => window.location.reload()}
      >
        Get New Word
      </button>
      {/* {(isGameLost || isGameWon) && ( */}
      {/*   <div className="mt-5 sm:mt-6 columns-2 dark:text-white"> */}
      {/*     <div> */}
      {/*       <h5>{NEW_WORD_TEXT}</h5> */}
      {/*       <Countdown */}
      {/*         className="text-lg font-medium text-black" */}
      {/*         date={tomorrow} */}
      {/*         daysInHours={true} */}
      {/*       /> */}
      {/*     </div> */}
      {/*     <button */}
      {/*       type="button" */}
      {/*       className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-brandgreen text-brandpink-100 dark:text-brandgreen dark:bg-brandpink-200 font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm" */}
      {/*       onClick={() => { */}
      {/*         shareStatus( */}
      {/*           guesses, */}
      {/*           isGameLost, */}
      {/*           isHardMode, */}
      {/*           isDarkMode, */}
      {/*           isHighContrastMode, */}
      {/*           handleShareToClipboard */}
      {/*         ) */}
      {/*       }} */}
      {/*     > */}
      {/*       {SHARE_TEXT} */}
      {/*     </button> */}
      {/*   </div> */}
      {/* )} */}
    </BaseModal>
  )
}
