export const PRIZES = [
  {
    answer: 'smith',
    prize: 'Smith Marine Floating Restaurant Dinner',
  },
  {
    answer: 'board',
    prize: 'Wakeboarding at Singapore Wake Park',
  },
  {
    answer: 'dance',
    prize: '$5,000 donation to Frontier Danceland',
  },
  {
    answer: 'fruit',
    prize: 'Mr Coconut Shake',
  },
  {
    answer: 'hotel',
    prize: 'One night stay at Capella (5th April?)',
  },
  {
    answer: 'japan',
    prize: 'Trip to Japan for snowboarding!',
  },
  {
    answer: 'plant',
    prize: 'Philodendron Florida Beauty',
  },
  {
    answer: 'river',
    prize: 'Dinner at Zorba, Greek restaurant by the river at Clarke Quay',
  },
  {
    answer: 'dress',
    prize: '$200 Love Bonito Voucher',
  },
  {
    answer: 'boobs',
    prize: '$200 Perk by Kate Gift Card',
  },
  {
    answer: 'cross',
    prize: 'Cross pendant + necklace',
  },
  {
    answer: 'chloe',
    prize: 'Joan Camera Bag by Chloe',
  },
  {
    answer: 'sugar',
    prize: 'Boba from Chicha San Chen',
  },
  {
    answer: 'novel',
    prize: '$100 Books Depository voucher ',
  },
  {
    answer: 'snack',
    prize: 'That bigass bag of snacks from Cheers',
  },
  {
    answer: 'bread',
    prize: 'Truffle Camembert & Brie sourdough focaccia from Mimieu Bakery',
  },
  {
    answer: 'touch',
    prize: 'Massage at Adeva Spa',
  },
  {
    answer: 'pasta',
    prize: 'Ravioli by Chef Pat',
  },
  {
    answer: 'beach',
    prize: 'Weekend trip to Bali',
  },
  {
    answer: 'forty',
    prize: 'Customised t-shirt (Pat to design!)',
  },
  {
    answer: 'hello',
    prize: 'Hello Kitty item',
  },
  {
    answer: 'kitty',
    prize: 'Hello Kitty item',
  },
  {
    answer: 'sleep',
    prize: 'Pillow from Capella',
  },
  {
    answer: 'cafes',
    prize: 'Sevens Kissaten  - Japanese cafe and teahouse',
  },
  {
    answer: 'steak',
    prize: 'Dinner at a steakhouse',
  },
  {
    answer: 'spicy',
    prize: 'Dinner at an Indian restaurant',
  },
  {
    answer: 'onsen',
    prize: 'Yunomori Onsen',
  },
  {
    answer: 'books',
    prize: 'Notebooks',
  },
  {
    answer: 'chair',
    prize: 'Secret Lab Chair',
  },
  {
    answer: 'paper',
    prize: 'Hello Kitty tissue paper',
  },
  {
    answer: 'watch',
    prize: 'New Apple Watch',
  },
  {
    answer: 'class',
    prize: 'Tapestry weaving at Craft Atelier ',
  },
  {
    answer: 'pearl',
    prize: 'Carrie K Star Mini Pearl Long Drop earrings',
  },
  {
    answer: 'stars',
    prize: 'Matching hand-poked star tattoos from Cia',
  },
  {
    answer: 'mixer',
    prize: 'Thermomix ',
  },
  {
    answer: 'photo',
    prize: 'A family photo taken in Hong Kong framed',
  },
  {
    answer: 'benji',
    prize: 'Yoga with Adriene’s Benji tee',
  },
  {
    answer: 'sushi',
    prize: 'Dinner at Shinji ',
  },
  {
    answer: 'drinks',
    prize: 'Patron Tequila',
  },
  {
    answer: 'meows',
    prize: 'Photography shoot with Mao at White Room Studio',
  },
]
